// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/icon/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".screenMain[data-v-f783c7c2]{width:100%;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;padding-bottom:55px;position:relative}.screenMain .mapChangePart[data-v-f783c7c2]{position:absolute;top:10px;left:10px;z-index:10}.screenMain .mapChangePart .layer[data-v-f783c7c2]{position:absolute;top:0;left:0;width:130px;height:34px;background:#1a3059;border:1px solid #35507c;border-radius:10px;box-shadow:5px 9px 30px 0 rgba(0,44,75,.2);padding:0 20px}.screenMain .mapChangePart .layer .desc[data-v-f783c7c2]{color:#fff;font-size:18px}.screenMain .mapChangePart .layerCtn[data-v-f783c7c2]{min-width:130px;margin-top:40px;padding:10px;background:#1a3059;border-radius:5px}.screenMain .mapChangePart .layerCtn .layerItem[data-v-f783c7c2]{margin-bottom:8px}.screenMain .mapChangePart .layerCtn .layerItem[data-v-f783c7c2]:last-child{margin-bottom:0}.screenMain .mapChangePart .name[data-v-f783c7c2]{color:#fff;font-size:14px;margin-left:10px}", ""]);
// Exports
module.exports = exports;
