<template>
    <div class="big_screen screenMain">
        <MyMap ref="myMap" :myMap.sync="myMap" :mapData="mapData"></MyMap>
        <!-- 图层 -->
        <div class="mapChangePart">
            <div
                class="layer pointer flex-between-y-center"
                @click="layerState = !layerState"
            >
                <div class="desc">图层</div>
                <img src="@image/bigScreen/icon/layer.png" />
            </div>
            <div v-show="layerState" class="layerCtn">
                <div
                    v-for="(item, index) in onOffList"
                    :key="index"
                    class="flex-between-y-center layerItem"
                >
                    <onOff
                        :onOffState="item.state"
                        :item="item"
                        :index="index"
                        @switchStateChange="switchStateChange"
                    ></onOff>
                    <div class="name">{{ item.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
import { mapActions } from "vuex";
import { derectionData } from "@libs/dailyInspectAreaList"; //
export default {
    name: "",
    components: {
        MyMap,
        OnOff,
    },
    data() {
        return {
            layerState: false,
            derectionData,
            areaGroup: null,
            myMap: null,
            mapData: {
                baseMapNum: null,
                mapTypeBtn: false,
                resetBtnRight: "107px",
            },
            onOffList: [],
            gqyxGroup: null,
            areaList: [],
            markerPopup: null,
            basicGroup: null,
            ycyHcq: null,
            dxtGroup: null,
        };
    },
    watch: {},
    mounted() {
        this.gqyxGroup = window.L.layerGroup().addTo(this.myMap);
        this.basicGroup = window.L.layerGroup().addTo(this.myMap);
        this.dxtGroup = window.L.layerGroup().addTo(this.myMap);
        this.areaGroup = window.L.layerGroup().addTo(this.myMap);
        this.drawBasicMap(this.basicGroup);
        this.loadGqyx(this.gqyxGroup);
        this.getAreaInfo();
        this.loadDxtMap(this.dxtGroup);
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    methods: {
        ...mapActions(["GetRcxcXcpq", "getVectorStyle"]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 加载高清影像
        loadGqyx(group) {
            let ign = new window.L.tileLayer(window.MAP_GQXY_50);
            window.L.layerGroup([ign]).addTo(group);
        },
        // 遗产要素分类 专题图
        async drawBasicMap(group) {
            this.clearLayersGroup(group);
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });

            let obj = JSON.parse(JSON.stringify(layerStyle));
            let ycshcq = [];
            obj.layers.map((item) => {
                if (item["source-layer"] == "遗产区划线") {
                    ycshcq.push(item);
                }
            });
            obj.layers = ycshcq;
            this.ycyHcq = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: obj,
            });

            this.ycyHcq.addTo(group);
            this.common.showVectorLayers();
            let myContainer = this.ycyHcq._glMap.getContainer();
            myContainer.style.zIndex = 50;

            // 遍历获取数据
            // layerStyle.layers.map((item) => {
            //     this.onOffList.map((itemInner) => {
            //         itemInner.sourceLayerList.map((itemInnerInner) => {
            //             if (item["source-layer"] == itemInnerInner) {
            //                 itemInner.idList.push(item.id);
            //             }
            //         });
            //     });
            // });
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            this.mapboxMap = allProvince1.getMapboxMap();
            this.mapboxMap.on("load", () => {
                this.onOffList.map((item) => {
                    if (item.name == "建设控制地带") {
                        item.idList.map((itemInner) => {
                            this.mapboxMap.setPaintProperty(
                                itemInner,
                                "line-opacity",
                                0
                            );
                        });
                    }
                });
            });
            // 4.天地图影像地图注记
            new window.L.TileLayer.WMTS(
                `http://t{s}.tianditu.gov.cn/cia_w/wmts?tk=${window.tk}`,
                {
                    zIndex: 10,
                    tileSize: 256,
                    layer: "cia",
                    style: "default",
                    tilematrixSet: "w",
                    crs: window.L.CRS.EPSG3857,
                    format: "tiles",
                    subdomains: "01234567",
                    minZoom: 3,
                    maxZoom: 17,
                    isBaseLayer: true,
                }
            ).addTo(group);
            this.common.showVectorLayers();
        },
        //获取片区数据
        async getAreaInfo() {
            let result = await this.GetRcxcXcpq({});
            result.map((item) => {
                this.derectionData.map((itemInner) => {
                    if (item.ID == itemInner.ID) {
                        Object.assign(item, itemInner);
                    }
                });
            });
            this.areaList = result;
            let areaColorList = [
                "#5a91fb",
                "#5ad9a7",
                "#5c7091",
                "#f7bd16",
                "#e86452",
                "#6ec9eb",
                "#955eb9",
                "#ff9944",
                "#1e9593",
                "#ff99c3",
                "#81511c",
                "#7d0022",
                "#556fb5",
                "#009944",
                "#0068b7",
                "#d31247",
                "#f260ce",
                "#7148f5",
                "#94b750",
                "#0b3da9",
                "#f4da80",
                "#863803",
                "#d2c7b6",
            ];
            this.areaList.map((item, index) => {
                let obj = {
                    name: item.QYMC,
                    group: window.L.layerGroup().addTo(this.myMap),
                    state: true,
                    color: areaColorList[index],
                    data: item,
                };
                this.onOffList.push(obj);
            });

            this.onOffList.map((item) => {
                this.drawXcq(item);
            });
        },
        // 基础底图
        async loadDxtMap(gropu) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_DXT,
            });
            let list = [];
            layerStyle.layers.map((item) => {
                if (item["source-layer"]) {
                    list.push(item);
                }
            });
            layerStyle.layers = Array.from(list);
            window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(gropu);
            this.common.showVectorLayers();
        },
        // 画 巡查区
        drawXcq(obj) {
            let group = obj.group;
            let item = obj.data;
            this.clearLayersGroup(group);

            let areaItem = JSON.parse(item.QYZB);
            // 巡查区
            let layer = window.L.geoJSON(areaItem, {
                style: () => {
                    return { color: obj.color };
                },
            }).addTo(group);

            layer.on("mouseover", () => {
                let lng = 0;
                let lat = 0;
                if (item.JD) {
                    lng = item.JD;
                    lat = item.WD;
                } else {
                    let center = window.turf.centerOfMass(areaItem);
                    lat = center.geometry.coordinates[1];
                    lng = center.geometry.coordinates[0];
                }
                this.markerPopup
                    .setLatLng([lat, lng])
                    .setContent(`<div class="tip">${item.QYMC}</div>`)
                    .openOn(this.myMap);
            });
            layer.on("mouseout", () => {
                this.markerPopup.removeFrom(this.myMap);
            });
        },
        // 更改图层
        switchStateChange(state, item, index) {
            if (!state) {
                this.clearLayersGroup(item.group);
            } else {
                this.drawXcq(item);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/* 主体 */
.screenMain {
    width: 100%;
    height: 100%;
    background: url("~@image/bigScreen/icon/bg.png") no-repeat;
    background-size: 100% 100%;
    padding-bottom: 55px;
    position: relative;
    // 图层控制
    .mapChangePart {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 130px;
            height: 34px;
            background: #1a3059;
            border: 1px solid #35507c;
            border-radius: 10px;
            box-shadow: 5px 9px 30px 0px rgba(0, 44, 75, 0.2);
            padding: 0 20px;

            .desc {
                color: #fff;
                font-size: 18px;
            }
        }

        .layerCtn {
            min-width: 130px;
            margin-top: 40px;
            padding: 10px;
            background: #1a3059;
            border-radius: 5px;
            .layerItem {
                margin-bottom: 8px;
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }
        }

        .name {
            color: #fff;
            font-size: 14px;
            margin-left: 10px;
        }
    }
}
</style>
